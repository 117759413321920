* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* #region Body */
body {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	line-height: 1.55;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	body {
		font-size: 1.375rem;
	}
}
/* #endregion Body */

/* #region Spacing */
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-title);
	margin-top: var(--space-30);
	margin-bottom: var(--space-30);
}

/* from-small,tablet-p */
@media (min-width: 1280px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: var(--space-80);
		margin-bottom: var(--space-80);
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child {
	margin-bottom: 0;
}
/* #endregion Spacing */

/* #region Type classes */
/**
 * Names used in Sketch design files:
 * - Mobile/TitleXL/Left/Dark
 * - Desktop/TitleXL/Left/Dark
 */
h1:not([class*="type-"]),
.type-a {
	font-family: var(--font-title);
	font-size: 2.375rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 500;
	hyphens: manual;
	letter-spacing: normal;
	line-height: 0.98;
	text-transform: uppercase;
}

@media (min-width: 375px) {
	h1:not([class*="type-"]),
	.type-a {
		font-size: 2.9375rem;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	h1:not([class*="type-"]),
	.type-a {
		font-size: 10rem;
		line-height: normal;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/Title/Left/Dark
 * - Desktop/Title/Left/Dark
 */
h2:not([class*="type-"]),
.type-b {
	font-family: var(--font-title);
	font-size: 2.375rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 500;
	hyphens: manual;
	letter-spacing: normal;
	line-height: 0.98;
	text-transform: uppercase;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	h2:not([class*="type-"]),
	.type-b {
		font-size: 2.625rem;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	h2:not([class*="type-"]),
	.type-b {
		font-size: 5.3125rem;
		line-height: 0.94;
	}
}

/**
 * Names used in Sketch design files:
 * - Mobile/Paragraph/Left/Dark
 * - Desktop/Paragraph/Left/Dark
 */
.type-c {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	hyphens: manual;
	letter-spacing: normal;
	line-height: 1.56;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.type-c {
		font-size: 1.375rem;
		line-height: 1.55;
	}
}

/**
 * Names used in Sketch design files:
 * - ButtonText/Regular
 * - ButtonText/Hover
 * - ButtonText/Disabled
 */
.type-d {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	hyphens: manual;
	letter-spacing: normal;
	line-height: normal;
}

/**
 * Names used in Sketch design files:
 * - Mobile/Caption/Left/Dark)
 * - Mobile/Caption/Right/Dark
 * - Desktop/Caption/Left/Dark
 * - Desktop/Caption/Right/Dark
 */
.type-e {
	font-family: var(--font-sans);
	font-size: 0.625rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	hyphens: manual;
	letter-spacing: normal;
	line-height: 1.3;
	text-transform: uppercase;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.type-e {
		font-size: 0.75rem;
	}
}

h3:not([class*="type-"]),
h4:not([class*="type-"]),
h5:not([class*="type-"]),
h6:not([class*="type-"]) {
	font-family: var(--font-title);
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.55;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	h3:not([class*="type-"]),
	h4:not([class*="type-"]),
	h5:not([class*="type-"]),
	h6:not([class*="type-"]) {
		font-size: 1.375rem;
	}
}
/* #endregion Type classes */

i,
em,
blockquote {
	font-style: italic;
}

/* Headings styles are annoyingly large in this theme. Ensure that styleguide headings are normal */
.sg-intro__title {
	font-size: 2.5rem !important;
}

.sg-section__title {
	font-size: 2rem !important;
}

.sg-nav__section__title {
	font-size: 1.75rem !important;
}
