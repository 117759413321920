.media {
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.media {
		display: flex;
	}

	.media--left {
		justify-content: flex-start;
	}

	.media--right {
		justify-content: flex-end;
	}
}

/* #region Content */
.media__content {
	margin: 0;
	width: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.media__content--9-columns {
		/* spans 9 columns */
		width: calc(((100% - (11 * var(--column-gap))) / (12 / 9)) + ((9 - 1) * var(--column-gap)));
	}

	.media__content--10-columns {
		/* spans 10 columns */
		width: calc(((100% - (11 * var(--column-gap))) / (12 / 10)) + ((10 - 1) * var(--column-gap)));
	}

	.media__content--centered {
		padding: 0 calc(((100% - (11 * 20px)) / 12));
		width: 100%;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.media__content--9-columns {
		/* spans 9 columns */
		width: calc(((100% - (11 * var(--column-gap-from-wide))) / (12 / 9)) + ((9 - 1) * var(--column-gap-from-wide)));
	}

	.media__content--10-columns {
		/* spans 10 columns */
		width: calc(((100% - (11 * var(--column-gap-from-wide))) / (12 / 10)) + ((10 - 1) * var(--column-gap-from-wide)));
	}
}
/* #endregion Content */

/* #region Source */
.media__source {
	width: 100%;
}

.media__source-outline {
	border: 1px solid black;
}
/* #endregion Source */

/* #region Caption */
.media__caption {
	overflow-wrap: break-word;
	padding-top: 0.4375rem;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

.media__caption__right {
	text-align: right;
}

/* up-to-small */
@media (max-width: 767px) {
	.media__caption__right:not(:first-child) {
		margin-top: 0.3125rem;
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.media__caption {
		display: flex;
		justify-content: space-between;
	}

	.media__caption__left {
		padding-right: calc(var(--column-gap) / 2);
		padding-left: var(--column-gap);
	}

	.media__caption__right {
		margin-left: auto;
		padding-right: var(--column-gap);
		padding-left: calc(var(--column-gap) / 2);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.media__caption {
		padding-top: 1.625rem;
	}

	.media__caption__left {
		padding-left: var(--column-gap-from-wide);
	}

	.media__caption__right {
		padding-right: var(--column-gap-from-wide);
	}
}
/* #endregion Caption */
