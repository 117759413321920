.frame {
	display: block;
	overflow: visible;
}

@media (prefers-color-scheme: dark) {
	.frame path {
		stroke: #ffffff;
	}
}
