.frame-for-navigation-overlay {
	opacity: 0;
	width: 100%;
	height: 100%;
}

.frame-for-navigation-overlay__media {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.frame-for-navigation-overlay__media {
		object-fit: cover;
		height: 100%;
	}

	.frame-for-navigation-overlay__media--contain {
		object-fit: contain;
	}
}
