.frame-for-viewport {
	background-color: var(--color-background);
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
}

.frame-for-viewport__lines-part {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (prefers-color-scheme: dark) {
	.frame-for-viewport__lines-part path {
		stroke: #ffffff;
	}
}

.frame-for-viewport__top,
.frame-for-viewport__bottom {
	display: flex;
	flex-basis: 50%;
	flex-grow: 2;
	flex-shrink: 2;
	flex-direction: row;
}

.frame-for-viewport__middle {
	display: flex;
	flex-basis: var(--size-frame-in-viewport-center-xsmall);
	flex-grow: 0;
	flex-shrink: 0;
	flex-direction: row;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.frame-for-viewport__middle {
		flex-basis: var(--size-frame-in-viewport-center-small);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.frame-for-viewport__middle {
		flex-basis: var(--size-frame-in-viewport-center-medium);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.frame-for-viewport__middle {
		flex-basis: var(--size-frame-in-viewport-center-large);
	}
}

.frame-for-viewport__top__left,
.frame-for-viewport__top__right,
.frame-for-viewport__bottom__left,
.frame-for-viewport__bottom__right,
.frame-for-viewport__middle__left,
.frame-for-viewport__middle__right {
	flex-basis: 50%;
	flex-grow: 2;
	flex-shrink: 2;
	position: relative;
}

.frame-for-viewport__top__center,
.frame-for-viewport__bottom__center {
	flex-basis: var(--size-frame-in-viewport-center-xsmall);
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.frame-for-viewport__top__center,
	.frame-for-viewport__bottom__center {
		flex-basis: var(--size-frame-in-viewport-center-small);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.frame-for-viewport__top__center,
	.frame-for-viewport__bottom__center {
		flex-basis: var(--size-frame-in-viewport-center-medium);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.frame-for-viewport__top__center,
	.frame-for-viewport__bottom__center {
		flex-basis: var(--size-frame-in-viewport-center-large);
	}
}

.frame-for-viewport__middle__center {
	flex-grow: 0;
	flex-shrink: 0;
	width: var(--size-frame-in-viewport-center-xsmall);
	height: var(--size-frame-in-viewport-center-xsmall);
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.frame-for-viewport__middle__center {
		width: var(--size-frame-in-viewport-center-small);
		height: var(--size-frame-in-viewport-center-small);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.frame-for-viewport__middle__center {
		width: var(--size-frame-in-viewport-center-medium);
		height: var(--size-frame-in-viewport-center-medium);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.frame-for-viewport__middle__center {
		width: var(--size-frame-in-viewport-center-large);
		height: var(--size-frame-in-viewport-center-large);
	}
}
