.page-title {
	margin-bottom: 1.125rem;
	width: 100%;
}

/* up-to-small */
@media (max-width: 767px) {
	.page-title {
		padding-top: calc(var(--space-30) * 2);
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.page-title {
		/* span 10 columns */
		width: calc(((100% - (11 * var(--column-gap))) / (12 / 10)) + ((10 - 1) * var(--column-gap)));
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.page-title {
		/* span 10 columns */
		width: calc(((100% - (11 * var(--column-gap-from-wide))) / (12 / 10)) + ((10 - 1) * var(--column-gap-from-wide)));
	}

	.page-title--large {
		margin-bottom: 3.75rem;
	}

	.page-title--xlarge {
		margin-bottom: 2.75rem;
	}
}
