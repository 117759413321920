.wysiwyg {
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.wysiwyg {
		display: flex;
	}

	.wysiwyg--left {
		justify-content: flex-start;
	}

	.wysiwyg--right {
		justify-content: flex-end;
	}

	.wysiwyg--left.wysiwyg--with-indent .wysiwyg__content {
		/* offset 1 column + gap */
		margin-left: calc(((100% - (11 * var(--column-gap))) / 12) + var(--column-gap));
	}

	.wysiwyg--right.wysiwyg--with-indent .wysiwyg__content {
		margin-right: calc(((100% - (11 * var(--column-gap))) / 12) + var(--column-gap));
	}

	.wysiwyg__content {
		/* span 5 columns */
		width: calc(((100% - (11 * var(--column-gap))) / (12 / 5)) + ((5 - 1) * var(--column-gap)));
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.wysiwyg--left.wysiwyg--with-indent .wysiwyg__content {
		/* offset 1 column + gap */
		margin-left: calc(((100% - (11 * var(--column-gap-from-wide))) / 12) + var(--column-gap-from-wide));
	}

	.wysiwyg--right.wysiwyg--with-indent .wysiwyg__content {
		margin-right: calc(((100% - (11 * var(--column-gap-from-wide))) / 12) + var(--column-gap-from-wide));
	}

	.wysiwyg__content {
		/* span 5 columns */
		width: calc(((100% - (11 * var(--column-gap-from-wide))) / (12 / 5)) + ((5 - 1) * var(--column-gap-from-wide)));
	}
}

/* #region Row */

/* from-wide,desktop */
@media (min-width: 1280px) {
	/* add extra spacing on first "introductory" paragraph of page */
	.l-site__content__inner > .row:first-child .wysiwyg:not(.wysiwyg--with-indent) {
		padding-bottom: 6.25rem; /* 100px */
	}
}
/* #endregion Row */
