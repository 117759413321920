.navigation-overlay {
	width: 100%;
	height: 100%;
}

.navigation-overlay__menu {
	padding-top: var(--space-30);
	position: relative;
	z-index: 1;
}

[data-state="open"] .navigation-overlay__menu {
	overflow-x: auto;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.navigation-overlay__menu {
		padding-top: var(--space-30);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.navigation-overlay__menu {
		padding-top: var(--space-50);
	}
}

.navigation-overlay__menu[data-forced-focus] {
	outline: 0;
}

.navigation-overlay__close {
	font-size: 0; /* to prevent unwanted whitespace top */
	position: absolute;
	top: var(--space-30);
	right: 0;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.navigation-overlay__close {
		top: var(--space-50);
	}
}

.navigation-overlay__close button {
	text-align: center;
	width: 5.5rem;
}

.navigation-overlay__frame {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.navigation-overlay__list {
	/* ul reset */
	margin: 0;
	padding: 1px 0 0 0; /* padding top 1px because font gets cutoff on top in some cases */
	/* ul reset */

	margin-top: calc(var(--space-30) + 2rem); /* one space + height of close button */
	overflow: auto;
	max-height: calc(100vh - var(--space-30) * 3);
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.navigation-overlay__list {
		margin-top: 0;
		max-height: calc(100vh - var(--space-30));
	}
}

.navigation-overlay__list__link {
	box-sizing: border-box;
	color: currentColor;
	display: inline-block;
	font-family: var(--font-title);
	font-size: 2.5rem;
	font-size: clamp(2rem, 8.5vh, 2.5rem);
	font-stretch: normal;
	font-style: normal;
	font-weight: 500;
	hyphens: auto;
	letter-spacing: normal;
	line-height: 0.94;
	margin-bottom: 0.2em;
	text-decoration: none;
	text-transform: uppercase;
	height: 0.8em;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.navigation-overlay__list__link {
		font-size: 2.625rem;
		font-size: clamp(2.125rem, 8.5vh, 3.125rem);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.navigation-overlay__list__link {
		font-size: 5.3125rem;
		font-size: clamp(3.125rem, 8.5vh, 5.8125rem);
		line-height: 0.94;
	}
}

.navigation-overlay__list__link:hover {
	border-bottom: 2px solid currentColor;
}

.navigation-overlay__list__link:focus {
	border-bottom: 2px solid currentColor;
}

.navigation-overlay__list__link[aria-current="page"] {
	color: var(--color-grey);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.navigation-overlay__list__link:hover {
		border-bottom: 4px solid currentColor;
	}

	.navigation-overlay__list__link:focus {
		border-bottom: 4px solid currentColor;
	}
}

.navigation-overlay__list__link:focus:not(:focus-visible):not(:hover) {
	border-bottom: 0;
}

.navigation-overlay__list__link:focus:not(:focus-visible) {
	outline: 0;
}
