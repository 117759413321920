:root {
	/* #region Colors */
	--color-foreground: #000000;
	--color-background: #ffffff;
	/*  */
	--color-grey: #767676;
	/*  */
	--color-focus-ring: #00fff9;

	/* Transparent color names */
	--color-foreground-alpha-0: rgb(0 0 0 / 0);
	--color-foreground-alpha-50: rgb(0 0 0 / 0.5);
	--color-foreground-alpha-100: rgb(0 0 0 / 1);

	/* #endregion Colors */

	/* #region Spaces */
	--space-30: 1.875rem;
	--space-50: 3.125rem;
	--space-80: 5rem;
	/* #endregion Spaces */

	/* #region Typography */
	--font-title: "AttilaSansClassic Medium", sans-serif;
	--font-sans: "Arimo Regular", sans-serif;
	/* #endregion Typography */

	/* #region Row */
	--row-spacing: 1.875rem; /* is --space-30 */
	--row-spacing-from-wide: 5rem; /* is --space-80 */
	/* #endregion Row */

	/* #region Wrapper */
	--wrapper-spacing: 1.875rem; /* is --space-30 */
	--wrapper-spacing-from-wide: 3.125rem; /* is --space-50 */
	/* #endregion Wrapper */

	/* #region Frames */
	--size-frame-in-viewport-center-xsmall: 220px;
	--size-frame-in-viewport-center-small: 450px;
	--size-frame-in-viewport-center-medium: 450px;
	--size-frame-in-viewport-center-large: 500px;
	/* #endregion Frames */

	/* #region Z-indices */
	--z-index-modal-dialog: 9000; /* more than styleguide UI */
	--z-index-menu-button: 8999;
	--z-index-content: 8998;
	/* #endregion Z-indices */

	/* #region Columns */
	--column-gap: 12px;
	--column-gap-from-wide: 26px;
	/* #endregion Columns */
}

@media (prefers-color-scheme: dark) {
	:root {
		/* #region Colors */
		--color-foreground: #ffffff;
		--color-background: #000000;
		/*  */
		--color-grey: #a1a1a1;
		/*  */
		--color-foreground-alpha-0: rgb(255 255 255 / 0);
		--color-foreground-alpha-50: rgb(255 255 255 / 0.5);
		--color-foreground-alpha-100: rgb(255 255 255 / 1);
		/* #endregion Colors */
	}
}
