.side-by-side-media {
}

/* up-to-small */
@media (max-width: 767px) {
	.side-by-side-media--full-width {
		padding-right: var(--space-30);
		padding-left: var(--space-30);
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.side-by-side-media {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

/* #region Item */

/* up-to-small */
@media (max-width: 767px) {
	.side-by-side-media__item:first-child {
		margin-bottom: var(--space-30);
	}

	.side-by-side-media__item.side-by-side-media__item--one-caption {
		margin-bottom: calc(1.75rem + var(--space-30));
	}

	.side-by-side-media__item.side-by-side-media__item--two-caption {
		margin-bottom: calc(2.875rem + var(--space-30));
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.side-by-side-media__item {
		flex-basis: calc(50% - var(--column-gap) / 2);
		flex-grow: 0;
		flex-shrink: 0;
	}

	.side-by-side-media__item.side-by-side-media__item--one-caption {
		margin-bottom: 1.75rem;
	}

	.side-by-side-media__item.side-by-side-media__item--two-caption {
		margin-bottom: 1.75rem;
	}

	.side-by-side-media--full-width .side-by-side-media__item {
		flex-basis: 50%;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.side-by-side-media__item {
		flex-basis: calc(50% - var(--column-gap-from-wide) / 2);
	}

	.side-by-side-media__item.side-by-side-media__item--one-caption {
		margin-bottom: 2.9375rem;
	}

	.side-by-side-media__item.side-by-side-media__item--two-caption {
		margin-bottom: 2.9375rem;
	}

	.side-by-side-media--full-width .side-by-side-media__item {
		flex-basis: 50%;
	}
}
/* #endregion Item */

/* #region Content */
.side-by-side-media__content {
	margin: 0;
	width: 100%;
}
/* #endregion Content */

/* #region Source */
.side-by-side-media__source {
	width: 100%;
}
/* #endregion Source */

/* #region Caption */
.side-by-side-media__caption {
	overflow-wrap: break-word;
	padding-top: 0.4375rem;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

.side-by-side-media__caption__right {
	text-align: right;
}

/* up-to-small */
@media (max-width: 767px) {
	.side-by-side-media__caption__right:not(:first-child) {
		margin-top: 0.3125rem;
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.side-by-side-media__caption {
		display: flex;
		justify-content: space-between;
	}

	.side-by-side-media__caption__left {
		padding-right: calc(var(--column-gap) / 2);
		padding-left: var(--column-gap);
	}

	.side-by-side-media__caption__right {
		margin-left: auto;
		padding-right: var(--column-gap);
		padding-left: calc(var(--column-gap) / 2);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.side-by-side-media__caption {
		padding-top: 1.625rem;
	}

	.side-by-side-media__caption__left {
		padding-left: var(--column-gap-from-wide);
	}

	.side-by-side-media__caption__right {
		padding-right: var(--column-gap-from-wide);
	}
}
/* #endregion Caption */

/* #region Media Aspect Ratio */
.side-by-side-media__item[data-aspect-ratio="2-3"] {
	aspect-ratio: 2 / 3;
	display: flex;
}

.side-by-side-media__item[data-aspect-ratio="5-7"] {
	aspect-ratio: 5 / 7;
	display: flex;
}

.side-by-side-media__item[data-aspect-ratio="3-4"] {
	aspect-ratio: 3 / 4;
	display: flex;
}

.side-by-side-media__item[data-aspect-ratio="1-1"] {
	aspect-ratio: 1 / 1;
	display: flex;
}

.side-by-side-media__item[data-aspect-ratio="4-3"] {
	aspect-ratio: 4 / 3;
	display: flex;
}

.side-by-side-media__item[data-aspect-ratio="7-5"] {
	aspect-ratio: 7 / 5;
	display: flex;
}

.side-by-side-media__item[data-aspect-ratio="3-2"] {
	aspect-ratio: 3 / 2;
	display: flex;
}

.side-by-side-media__item[data-aspect-ratio="16-9"] {
	aspect-ratio: 16 / 9;
	display: flex;
}

.side-by-side-media__source {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.side-by-side-media__source,
	.side-by-side-media__content .frame-with-media {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
/* #endregion Media Aspect Ratio */
