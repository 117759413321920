.l-site {
}

/* #region Menu Button */
.l-site__menu-button {
	font-size: 0;
	position: fixed;
	top: var(--wrapper-spacing);
	right: var(--wrapper-spacing);
	z-index: var(--z-index-menu-button);
}

.l-site__menu-button button {
	display: block;
	text-align: center;
	width: 5.5rem;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site__menu-button {
		top: var(--wrapper-spacing-from-wide);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-site__menu-button {
		right: var(--wrapper-spacing-from-wide);
	}
}
/* #endregion Menu Button */

/* #region Content */
.l-site__content {
	padding-top: var(--wrapper-spacing);
	position: relative;
	width: 100%;
	z-index: var(--z-index-content);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-site__content {
		padding-top: var(--wrapper-spacing-from-wide);
	}
}
/* #endregion Content */
