.footer-navigation {
	font-family: var(--font-title);
	padding-top: var(--space-30);
	padding-bottom: var(--space-30);
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.footer-navigation {
		padding-bottom: 0;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.footer-navigation {
		padding-top: 0;
		/* spans 9 columns */
		width: calc(((100% - (11 * var(--column-gap-from-wide))) / (12 / 9)) + ((9 - 1) * var(--column-gap-from-wide)));
	}
}

/* #region List */
.footer-navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.footer-navigation__list__link {
	color: var(--color-foreground);
	display: inline-block;
	line-height: 0.94;
	margin-bottom: 0.2em;
	text-decoration: none;
	transition: color 96ms linear;
	height: 0.8em;
}

/* up-to-small */
@media (max-width: 767px) {
	.footer-navigation__list__link.type-b {
		font-size: 2.5rem;
	}
}

.footer-navigation__list__link:hover {
	border-bottom: 2px solid currentColor;
	margin-bottom: calc(0.2em - 2px);
}

.footer-navigation__list__link[aria-current="page"] {
	color: var(--color-grey);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.footer-navigation__list__link:hover {
		border-bottom: 4px solid currentColor;
		margin-bottom: calc(0.2em - 4px);
	}
}

.footer-navigation__list__link:focus:not(:focus-visible):not(:hover) {
	border-bottom: 0;
}
/* #endregion List */

/* #region Credits */
.footer-navigation__container {
	display: flex;
	flex-direction: column;
	padding-top: var(--space-30);
	padding-bottom: var(--space-30);
	justify-content: center;
}

.footer-navigation__credits {
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
}

.footer-navigation__credits p {
	text-align: center;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.footer-navigation__container {
		flex-direction: row;
		padding-top: var(--space-50);
		padding-right: var(--space-50);
		padding-bottom: var(--space-50);
		padding-left: var(--space-50);
		justify-content: space-between;
		align-items: flex-end;
	}

	.footer-navigation__credits {
		margin-bottom: 1rem;
		justify-content: flex-end;
	}
}
/* #endregion Credits */
