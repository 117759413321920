.frame-with-media {
	align-self: flex-start; /* important if this element is used in flex layouts */
	position: relative;
	width: 100%;
}

.frame-with-media .media {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: auto;
}

.frame-with-media .frame {
	width: 100%;
	height: auto;
}
