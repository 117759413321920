.row {
	box-sizing: border-box;
	padding-bottom: var(--row-spacing);
	position: relative;
}

/* from-wide */
@media (min-width: 1280px) {
	.row {
		padding-bottom: var(--row-spacing-from-wide);
	}
}
