.l-landing {
}

/* #region Menu Button */
.l-landing__menu-button {
	font-size: 0;
	position: fixed;
	top: var(--wrapper-spacing);
	right: var(--wrapper-spacing);
	z-index: var(--z-index-menu-button);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-landing__menu-button {
		top: var(--wrapper-spacing-from-wide);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-landing__menu-button {
		right: var(--wrapper-spacing-from-wide);
	}
}

.l-landing__menu-button button {
	display: block;
	text-align: center;
	width: 5.5rem;
}
/* #endregion Menu Button */

/* #region Content */
.l-landing__content {
	position: relative;
	width: 100%;
	z-index: var(--z-index-content);
}

.l-landing__content__inner {
	position: relative;
	width: 100%;
	min-height: 100vh;
	z-index: 1;
}

.l-landing__logo {
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - calc(var(--space-30)) * 2);
	height: auto;
	z-index: 0;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-landing__logo {
		width: calc(100% - calc(var(--column-gap-from-wide)) * 4);
	}
}

.l-landing__paint {
	width: 100%;
	min-height: 100vh;
	z-index: 1;
}
/* #endregion Content */

/* Region subtitle */
.l-landing__subtitle {
	font-family: var(--font-title);
	font-size: 1.375rem;
	line-height: initial;
	margin-top: var(--space-30);
	opacity: 1;
	text-align: center;
	text-transform: uppercase;
	transition: opacity 0.5s;
}

/* from-small,tablet */
@media (min-width: 768px) {
	.l-landing__subtitle {
		margin-top: var(--space-50);
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.l-landing__subtitle {
		font-size: 2.25rem;
		margin-top: var(--space-80);
		opacity: 0;
	}
}
/* #endregion Subtitle */
