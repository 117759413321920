.button {
	/* reset */
	appearance: none;
	background: none;
	/* reset */

	background-color: var(--color-background);
	border: solid 1px var(--color-foreground);
	border-radius: 1.25rem;
	color: var(--color-foreground);
	cursor: pointer;
	display: inline-block;
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	outline: none; /* not ideal for a11y */
	padding: 0.437rem 1.1875rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition:
		background-color 96ms linear,
		color 96ms linear;
}

.button[disabled] {
	border-color: var(--color-grey);
	color: var(--color-grey);
	pointer-events: none;
	user-select: none;
}

.button:not([disabled]):focus {
	box-shadow: 0 0 10px 0 var(--color-focus-ring);
}

@media (prefers-color-scheme: dark) {
	/* In dark mode the focus ring is hard to spot, giving the box radius a bit of spread helps */
	.button:not([disabled]):focus {
		box-shadow: 0 0 10px 1px var(--color-focus-ring);
	}
}

.button:not([disabled]):focus:not(:focus-visible) {
	box-shadow: none;
}

.button:not([disabled]):hover {
	background-color: var(--color-foreground);
	color: var(--color-background);
}
