.wrapper {
	box-sizing: border-box;
	padding-right: var(--wrapper-spacing);
	padding-left: var(--wrapper-spacing);
	width: 100%;
}

/* from-large */
@media (min-width: 1440px) {
	.wrapper {
		padding-right: var(--wrapper-spacing-from-wide);
		padding-left: var(--wrapper-spacing-from-wide);
	}
}

@media (max-width: 340px) {
	.wrapper--navigation-overlay {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}
