/*
	This component doesn't use any spacer var's on purpose.
	It also has a lot more breakpoints than usual.
	Everything here was placed by tweaking pixel values to get as close to design as possible.
	If any var would change, this would break.
	If content were to change, this would break.
*/

.frame-for-error-page {
}

.frame-for-error-page__title {
	margin-top: -30px;
	margin-right: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 8px);
	margin-left: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 8px);
	padding-bottom: 2rem;
}

@media (min-width: 350px) {
	.frame-for-error-page__title {
		margin-right: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 16px);
		margin-left: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 16px);
	}
}

@media (min-width: 500px) {
	.frame-for-error-page__title {
		margin-right: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 60px);
		margin-left: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 60px);
	}
}

/*
	from-small,tablet-p
*/

@media (min-width: 768px) {
	.frame-for-error-page__title {
		margin-top: 0;
		margin-right: 0;
		margin-left: 0;
		padding-top: 2rem;
		padding-right: 2rem;
		padding-bottom: 2rem;
		padding-left: 2rem;
	}
}

/*
	from-wide
*/

@media (min-width: 1280px) {
	.frame-for-error-page__title {
		margin-right: -275px;
		margin-left: -210px;
		padding-top: 2.5rem;
		padding-right: 0;
		padding-bottom: 3.75rem;
		padding-left: 0;
	}
}

/*
	from-large
*/

@media (min-width: 1440px) {
	.frame-for-error-page__title {
		margin-right: -225px;
		margin-left: -210px;
		padding-top: 5rem;
		padding-bottom: 3.75rem;
	}
}

.frame-for-error-page__message {
	margin-right: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 8px);
	margin-left: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 8px);
}

@media (min-width: 350px) {
	.frame-for-error-page__message {
		margin-right: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 16px);
		margin-left: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 16px);
	}
}

@media (min-width: 500px) {
	.frame-for-error-page__message {
		margin-right: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 60px);
		margin-left: calc(calc(calc(calc(100vw - var(--size-frame-in-viewport-center-xsmall)) / 2) * -1) + 60px);
	}
}

/*
	from-small,tablet-p
*/

@media (min-width: 768px) {
	.frame-for-error-page__message {
		margin-right: 0;
		margin-left: 0;
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

/*
	from-wide
*/

@media (min-width: 1280px) {
	.frame-for-error-page__message {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}
}

/*
	from-wide
*/

@media (min-width: 1440px) {
	.frame-for-error-page__message {
		padding-right: 3.75rem;
		padding-left: 3.75rem;
	}
}
