/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.landing-paint {
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

.landing-paint__image {
	animation-duration: 256ms;
	animation-name: landingPaintFadeIn;
	position: absolute;
	transform: translate(-50%, -50%);
	height: auto;
}

.landing-paint__image[not-painted] {
	display: none;
}

@keyframes landingPaintFadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
